import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Nullable } from '@tager/web-core';

import {
  BusyDateCityType,
  BusyDateType,
  CoefficientCity,
  CoefficientType,
} from '@/typings/model';
import { AppState } from '@/store/store';
import { getBusyDates, getCoefficients } from '@/services/requests/orders';
import { dateToString, stringToDate } from '@/utils/common';
import { generateTimeOptions } from '@/modules/Order/utils';

type DateTimeState = {
  availableDateTimeSlot: string | null;

  busyDatesLoaded: boolean;
  busyDates: Array<BusyDateCityType>;

  coefficientsLoaded: boolean;
  coefficients: Array<CoefficientCity>;

  selectedDate: string | null;
  selectedTime: string | null;
  selectedCoefficient: number | null;
};

const initialState: DateTimeState = {
  availableDateTimeSlot: null,

  busyDates: [],
  busyDatesLoaded: false,

  coefficients: [],
  coefficientsLoaded: false,

  selectedDate: null,
  selectedTime: null,
  selectedCoefficient: null,
};

export const loadBusyDatesThunk = createAsyncThunk<Array<BusyDateCityType>>(
  'dateTime/loadRoomsAndBathroomsServicesThunk',
  async () => {
    try {
      return await getBusyDates();
    } catch (error) {
      return [];
    }
  }
);

export const loadDateCoefficientsThunk = createAsyncThunk<
  Array<CoefficientCity>,
  { id?: number }
>('dateTime/loadDateCoefficients', async (args) => {
  try {
    return await getCoefficients(args.id);
  } catch (error) {
    return [];
  }
});

const dateTimeSlice = createSlice({
  name: 'datetime',
  initialState,
  reducers: {
    reset: () => initialState,
    setSelectedTime(state, action: PayloadAction<string | null>) {
      state.selectedTime = action.payload;
      state.selectedCoefficient = null;
    },
    setSelectedDate(state, action: PayloadAction<Date | null>) {
      state.selectedDate = action.payload ? dateToString(action.payload) : null;
      state.selectedCoefficient = null;
    },
    setCoefficient(state, action: PayloadAction<number>) {
      state.selectedCoefficient = action.payload;
    },
    addAvailableTimeSlot(state, action: PayloadAction<string>) {
      state.availableDateTimeSlot = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadBusyDatesThunk.pending, (state, action) => {
        state.busyDates = [];
        state.busyDatesLoaded = false;
      })
      .addCase(loadBusyDatesThunk.fulfilled, (state, action) => {
        state.busyDates = action.payload;
        state.busyDatesLoaded = true;
      });

    builder
      .addCase(loadDateCoefficientsThunk.pending, (state, action) => {
        state.coefficients = [];
        state.coefficientsLoaded = false;
      })
      .addCase(loadDateCoefficientsThunk.fulfilled, (state, action) => {
        state.coefficients = action.payload;
        state.coefficientsLoaded = true;
      });
  },
});

export function selectBusyDates(state: AppState): Array<BusyDateType> {
  const found = state.orderForm.datetime.busyDates.find(
    (item) => item.city === state.orderForm.address.cityId
  );

  let busyDates = found ? found.items : [];

  if (state.orderForm.datetime.availableDateTimeSlot) {
    const availableDateTimeSlot =
      state.orderForm.datetime.availableDateTimeSlot;

    const date = availableDateTimeSlot.substring(0, 10);
    const time = availableDateTimeSlot.substring(11, 16);

    busyDates = busyDates.map((item) => {
      if (item.date !== date) {
        return item;
      }

      if (item.times.length === 0) {
        const times: string[] = [];

        generateTimeOptions().forEach((label) => {
          if (label !== time) {
            times.push(label);
          }
        });

        return { ...item, times };
      } else {
        return { ...item, times: item.times.filter((item) => item !== time) };
      }
    });
  }

  return busyDates;
}

export function selectSelectedDate(state: AppState): Date | null {
  return state.orderForm.datetime.selectedDate
    ? stringToDate(state.orderForm.datetime.selectedDate)
    : null;
}

export function selectSelectedTime(state: AppState): string | null {
  return state.orderForm.datetime.selectedTime;
}

export function selectBusyDatesLoaded(state: AppState): boolean {
  return state.orderForm.datetime.busyDatesLoaded;
}

export function selectCoefficients(state: AppState): Array<CoefficientType> {
  return (
    state.orderForm.datetime.coefficients.find(
      (item) => item.city === state.orderForm.address.cityId
    )?.items || []
  );
}

export function selectCoefficientsLoaded(state: AppState): boolean {
  return state.orderForm.datetime.coefficientsLoaded;
}

export function selectSelectedDateCoefficient(state: AppState): number {
  if (state.orderForm.datetime.selectedCoefficient) {
    return state.orderForm.datetime.selectedCoefficient;
  }

  return (
    selectCoefficients(state).find(
      (item) => item.date === state.orderForm.datetime.selectedDate
    )?.coefficient || 1
  );
}

export const dateTimeActions = dateTimeSlice.actions;

export default dateTimeSlice.reducer;

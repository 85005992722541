import React, { useEffect } from 'react';

import { cookie } from '@tager/web-core';

import { useTypedDispatch, useTypedSelector } from '@/store/store';
import {
  loadRoomsAndBathroomsServicesThunk,
  loadWindowsAndBalconiesServicesThunk,
  setFirstServiceQuantity,
  setSecondServiceQuantity,
} from '@/modules/Order/store/services';
import {
  loadBusyDatesThunk,
  loadDateCoefficientsThunk,
} from '@/modules/Order/store/datetime';
import { OrderFormMode } from '@/enums/order-form-mode';
import {
  loadOrderSettings,
  loadOrderUserProfile,
  selectOrderMode,
  setInitialDataAsLoaded,
} from '@/modules/Order/store/common';
import { getInitialOrderData } from '@/services/requests/orders';
import { addressActions } from '@/modules/Order/store/address';
import OrderLoader from '@/modules/Order/components/OrderLoader';
import { selectCities } from '@/store/reducers/cities';

const OrderCreateLoader: React.FC = () => {
  const mode = useTypedSelector(selectOrderMode);
  const dispatch = useTypedDispatch();

  const cities = useTypedSelector(selectCities);
  const cookieCityId = cookie.get('cityId');

  const loadInitialData = () => {
    getInitialOrderData().then((response) => {
      if (response.data.address) {
        dispatch(
          addressActions.setCity(
            cities.find((item) => item.id === response.data.address?.city) ||
              cities[0]
          )
        );
        dispatch(addressActions.setStreet(response.data.address.street));
        dispatch(addressActions.setHouse(response.data.address.house || ''));
        dispatch(addressActions.setFrame(response.data.address.frame || ''));
        dispatch(addressActions.setFlat(response.data.address.flat || ''));
        dispatch(
          addressActions.setEntrance(response.data.address.entrance || '')
        );
        dispatch(addressActions.setFloor(response.data.address.floor));
        dispatch(
          addressActions.setIntercomCode(
            response.data.address.intercomCode || ''
          )
        );
      } else {
        if (cookieCityId) {
          const cityModel = cities.find((item) => item.id === +cookieCityId);
          if (cityModel) {
            dispatch(addressActions.setCity(cityModel));
          }
        }
      }

      if (response.data.rooms) {
        dispatch(setFirstServiceQuantity(response.data.rooms.rooms));
        dispatch(setSecondServiceQuantity(response.data.rooms.bathrooms));
      } else if (response.data.windows) {
        dispatch(setFirstServiceQuantity(response.data.windows.windows));
        dispatch(setSecondServiceQuantity(response.data.windows.balconies));
      }

      dispatch(setInitialDataAsLoaded());
    });
  };

  useEffect(() => {
    dispatch(loadOrderSettings());
    dispatch(loadOrderUserProfile());
    dispatch(loadBusyDatesThunk());
    dispatch(loadDateCoefficientsThunk({}));

    loadInitialData();
  }, []);

  useEffect(() => {
    if (mode === OrderFormMode.Rooms) {
      dispatch(loadRoomsAndBathroomsServicesThunk());
    } else if (mode === OrderFormMode.Windows) {
      dispatch(loadWindowsAndBalconiesServicesThunk());
    }
  }, [mode]);

  return <OrderLoader />;
};

export default OrderCreateLoader;

import React from 'react';
import styled, { css } from 'styled-components';

import TextInput from '@/components/TextInput';
import Select from '@/components/Select';
import {
  addressActions,
  selectAddressCityId,
  selectAddressEntrance,
  selectAddressFlat,
  selectAddressFloor,
  selectAddressFrame,
  selectAddressHouse,
  selectAddressIntercomCode,
  selectAddressStreet,
  selectAddressType,
} from '@/modules/Order/store/address';
import { useTypedDispatch, useTypedSelector } from '@/store/store';
import OrderFormSection from '@/modules/Order/components/OrderFormSection';
import { selectOrderSubmitLoading } from '@/modules/Order/store/first-step';
import { media } from '@/utils/mixin';
import { selectCities } from '@/store/reducers/cities';
import { dateTimeActions } from '@/modules/Order/store/datetime';
import OrderFormHouseCheckbox from '@/modules/Order/components/OrderFormHouseCheckbox';
import { OrderHouseType } from '@/enums/order-house-type';
import { OrderFormMode } from '@/enums/order-form-mode';
import { selectOrderMode } from '@/modules/Order/store/common';

const OrderFormAddress: React.FC = () => {
  const submitLoading = useTypedSelector(selectOrderSubmitLoading);

  const dispatch = useTypedDispatch();

  const {
    setFlat,
    setFrame,
    setHouse,
    setFloor,
    setStreet,
    setEntrance,
    setIntercomCode,
    setCity,
    setType,
  } = addressActions;

  const mode = useTypedSelector(selectOrderMode);
  const cities = useTypedSelector(selectCities);
  const cityId = useTypedSelector(selectAddressCityId);
  const street = useTypedSelector(selectAddressStreet);
  const frame = useTypedSelector(selectAddressFrame);
  const house = useTypedSelector(selectAddressHouse);
  const floor = useTypedSelector(selectAddressFloor);
  const flat = useTypedSelector(selectAddressFlat);
  const entrance = useTypedSelector(selectAddressEntrance);
  const intercomCode = useTypedSelector(selectAddressIntercomCode);
  const type = useTypedSelector(selectAddressType);

  const citiesOptions = cities.map((item) => {
    return {
      value: item.id,
      label: item.name,
    };
  });

  const onCitySelect = (cityId: number | null) => {
    dispatch(setCity(cities.find((item) => item.id === cityId) || cities[0]));
    dispatch(dateTimeActions.setSelectedDate(null));
    dispatch(dateTimeActions.setSelectedTime(null));
  };

  const onHouseToggle = (value: boolean) => {
    dispatch(setType(value ? OrderHouseType.House : OrderHouseType.Apartment));
  };

  return (
    <OrderFormSection blocked={submitLoading} title="Адрес уборки">
      <>
        <Grid type={type}>
          <Select
            label="Город"
            options={citiesOptions}
            value={cityId}
            onChange={onCitySelect}
          />

          <TextInput
            id="order-form-street"
            label="Улица"
            value={street}
            onChange={(e) => dispatch(setStreet(e.target.value))}
          />

          <TextInput
            id="order-form-house"
            label="Дом"
            numeric
            value={house}
            onChange={(e) => dispatch(setHouse(e.target.value))}
            maxLength={4}
          />

          {type === OrderHouseType.Apartment ? (
            <>
              <TextInput
                label="Корпус"
                value={frame}
                onChange={(e) => dispatch(setFrame(e.target.value))}
                maxLength={3}
              />
              <TextInput
                label="Квартира"
                numeric
                value={flat}
                onChange={(e) => dispatch(setFlat(e.target.value))}
                maxLength={4}
              />
              <TextInput
                label="Подъезд"
                numeric
                value={entrance}
                onChange={(e) => dispatch(setEntrance(e.target.value))}
                maxLength={2}
              />
              <TextInput
                label="Этаж"
                numeric
                value={floor}
                onChange={(e) =>
                  dispatch(
                    setFloor(e.target.value === '' ? null : +e.target.value)
                  )
                }
                maxLength={2}
              />
              <TextInput
                label="Код домофона"
                placeholder="Если есть"
                value={intercomCode}
                onChange={(e) => dispatch(setIntercomCode(e.target.value))}
                maxLength={40}
              />
            </>
          ) : null}
        </Grid>
      </>
      {mode === OrderFormMode.Rooms ? (
        <HouseCheckbox
          checked={type === OrderHouseType.House}
          onChange={onHouseToggle}
        />
      ) : null}
    </OrderFormSection>
  );
};

const HouseCheckbox = styled(OrderFormHouseCheckbox)`
  margin-top: 20px;
`;

const Grid = styled.div<{ type: OrderHouseType }>`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 20px;

  > *:nth-child(1) {
    grid-column-start: 1;
    grid-column-end: 3;
  }

  > *:nth-child(2) {
    grid-column-start: 3;
    grid-column-end: 7;
  }

  ${(props) =>
    props.type === OrderHouseType.House &&
    css`
      > *:nth-child(2) {
        grid-column-start: 3;
        grid-column-end: 6;
      }
    `}

  ${media.tabletSmall(css`
    grid-template-columns: 1fr 1fr;

    > *:nth-child(1) {
      grid-column-start: 1;
      grid-column-end: 3;
    }

    > *:nth-child(2) {
      grid-column-start: 1;
      grid-column-end: 3;
    }

    gap: 20px;
  `)}
`;

export default OrderFormAddress;

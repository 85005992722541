import { Nullable, request, ResponseBody } from '@tager/web-core';

import {
  Address,
  BusyDateCityType,
  CoefficientCity,
  PromocodeType,
  ServiceType,
} from '@/typings/model';
import { OrderHouseType } from '@/enums/order-house-type';

export type OrderRequestBody = {
  city: number | null;
  phone: string;
  rooms?: number;
  bathrooms?: number;
  windows?: number;
  balconies?: number;
  utmSource?: string;
  utmCampaign?: string;
};

export type CreateOrderRequest = {
  date: string;
  time: string;
  type: 'ROOMS' | 'WINDOWS';
  houseType: OrderHouseType;
  services: number[];
  promo: string | null;
  referral: string | null;
  address: {
    city: number | null;
    street: string;
    house: string;
    frame: string | null;
    entrance: string | null;
    floor: number | null;
    apartment: string | null;
    intercomCode: string | null;
  };
  name: string;
  email: string;
  comment: string;
};

export type UpdateOrderRequest = {
  date: string;
  time: string;
  houseType: OrderHouseType;
  services: number[];
  address: {
    city: number | null;
    street: string;
    house: string;
    frame: string | null;
    entrance: string | null;
    floor: number | null;
    apartment: string | null;
    intercomCode: string | null;
  };
  secondaryServices: Array<{
    id: number;
    value: number;
  }>;
  keysDeliveryAddress: string;
  promo: string | null;
  comment: string;
};

export type SetSecondaryServicesRequest = {
  services: Array<{
    id: number;
    value: number;
  }>;
  keysDeliveryAddress: string;
};

export type OrderUserProfile = {
  balance: number;
  haveOrders: boolean;
};

export type OrderInitialData = {
  address: Nullable<{
    city: number;
    street: string;
    house: string;
    frame: string | null;
    flat: string | null;
    entrance: string | null;
    floor: number | null;
    intercomCode: string | null;
  }>;
  rooms: Nullable<{
    rooms: number;
    bathrooms: number;
  }>;
  windows: Nullable<{
    windows: number;
    balconies: number;
  }>;
};

export type OrderType = {
  id: number;
  datetime: string;
  type: OrderHouseType;
  city: number;
  address: Address;
  addressText: string;
  subscriptionPeriod: string;
  price: number;
  confirmed: boolean;
  shouldConfirm: boolean;
  isSubscription: number;
  isWindowsOrder: boolean;
  keyDeliveryAddress: Nullable<string>;
  afterBuilding: boolean;
  services: Array<ServiceType>;
  needReview: boolean;
  comment: Nullable<string>;
  promocode: Nullable<PromocodeType>;
  dayCoefficient: number;
  usedBalance: number;
  discount: number;
  referralCode: Nullable<string>;
  referralDiscount: Nullable<number>;
};

export type OrderSettingsSubscription = {
  id: string;
  discount: number;
  name: string;
  ordersInMonth: number;
};
export type OrderSettings = {
  minTotal: number;
  subscriptions: Array<OrderSettingsSubscription>;
};

export function setOrderStartRequest(body: OrderRequestBody): Promise<any> {
  return request.post({
    path: '/orders/start',
    body: {
      ...body,
      utmSource: localStorage.getItem('utmSource') || null,
      utmCampaign: localStorage.getItem('utmCampaign') || null,
    },
  });
}

export function createOrder(
  body: CreateOrderRequest
): Promise<ResponseBody<{ id: number }>> {
  return request.post({ path: `/orders/create`, body });
}

export function updateOrder(
  id: number,
  body: UpdateOrderRequest
): Promise<ResponseBody<{ id: number }>> {
  return request.post({ path: `/orders/${id}`, body });
}

export function setSecondaryServices(
  id: number,
  body: SetSecondaryServicesRequest
): Promise<ResponseBody> {
  return request.post({ path: `/orders/${id}/services`, body });
}

export function setOrderSubscription(
  id: number,
  period: string
): Promise<ResponseBody> {
  return request.post({ path: `/orders/${id}/subscription`, body: { period } });
}

export function applyPromoCode(
  code: string,
  date?: string,
  orderId?: string
): Promise<ResponseBody<PromocodeType>> {
  return request.post({
    path: `/orders/promo-code`,
    body: { code, date, orderId },
  });
}

export function applyReferralCode(
  code: string
): Promise<ResponseBody<PromocodeType>> {
  return request.post({
    path: `/orders/referral`,
    body: { code },
  });
}

export function getBusyDates(): Promise<Array<BusyDateCityType>> {
  return request.get({ path: `/orders/busy-dates` });
}

export function getOrderSettings(): Promise<ResponseBody<OrderSettings>> {
  return request.get({ path: `/orders/settings` });
}

export function getCoefficients(id?: number): Promise<Array<CoefficientCity>> {
  return request.get({ path: `/orders/${id ? id + '/' : ''}coefficients` });
}

export function getInitialOrderData(): Promise<ResponseBody<OrderInitialData>> {
  return request.get({ path: `/orders/initial-data` });
}

export function getOrderUserProfile(): Promise<ResponseBody<OrderUserProfile>> {
  return request.get({ path: `/orders/user-profile` });
}

export function getOrderById(
  orderId: string | number
): Promise<ResponseBody<OrderType>> {
  return request.get({ path: `/orders/${orderId}` });
}

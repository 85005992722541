import { request, ResponseBody } from '@tager/web-core';

import {
  BusyDateType,
  FormValuesType,
  OrderServiceType,
  OrderType,
  PrimaryOrderServiceType,
  SeoPage,
  UserProfileType,
  UserType,
} from '@/typings/model';

type SuccessData = { success: boolean };

export type CleaningAfterRepairCostRequestBody = {
  name: string;
  phone: string;
  rooms: number;
  bathrooms: number;
  isFurnitureCleaning: boolean;
  isWindows: boolean;
  withVacuum: boolean;
  withSteam: boolean;
};

export function requestCleaningAfterRepairCost(
  body: CleaningAfterRepairCostRequestBody,
  requestPath: string
): Promise<SuccessData> {
  return request.post({
    path: requestPath,
    body,
  });
}

export type OfficeCleaningCostRequestBody = {
  name: string;
  phone: string;
  isWindows: boolean;
  isAfterRepair: boolean;
};

export function requestOfficeCleaningCost(
  body: OfficeCleaningCostRequestBody
): Promise<SuccessData> {
  return request.post({
    path: '/leads/office',
    body,
  });
}

export type OrderRequestBody = {
  phone: string;
  rooms?: number;
  bathrooms?: number;
  windows?: number;
  balconies?: number;
  form?: string;
};

export type CareerRequestBody = {
  name: string;
  age: number;
  phone: string;
};

export function requestCareer(body: CareerRequestBody): Promise<SuccessData> {
  return request.post({ path: '/leads/career', body });
}

export function getPageByAlias(alias: string): Promise<ResponseBody<SeoPage>> {
  return request.get({ path: '/seo', params: { alias } });
}

export function getSeoPages(): Promise<ResponseBody<Array<string>>> {
  return request.get({ path: '/seo' });
}

export type OrderReviewBody = {
  rating: number;
  message: string;
};

export function submitOrderReview(
  alias: string,
  body: OrderReviewBody
): Promise<SuccessData> {
  return request.post({
    path: `/public-order-reviews/submit/${alias}`,
    body,
  });
}

export type OrderShort = {
  id: number;
  datetime: string;
  address: string;
};

export function getOrderByAlias(
  alias: string
): Promise<ResponseBody<OrderShort>> {
  return request.get({ path: `/public-order-reviews/get-order/${alias}` });
}

export type SignInPayload = {
  email: string;
  password: string;
};

/** Auth */
export type OAuthTokenResponseBody = {
  tokenType: string;
  expiresAt: string;
  accessToken: string;
  scopes: Array<string>;
  refreshToken: string;
};

export function signIn(
  payload: SignInPayload
): Promise<ResponseBody<OAuthTokenResponseBody>> {
  return request.post({
    path: '/tager/auth/user',
    body: { ...payload, clientId: 1 },
    fetchOptions: {
      headers: { 'Accept-Language': 'ru', 'Content-Type': 'application/json' },
    },
  });
}

/** Profile */
export function getUserProfile(): Promise<ResponseBody<UserProfileType>> {
  return request.get({ path: '/profile' });
}

export type UpdateProfilePayload = {
  name: string;
  email: string;
  phone: string;
};

export function updateProfile(
  payload: UpdateProfilePayload
): Promise<ResponseBody<UserProfileType>> {
  return request.post({ path: '/profile', body: { ...payload } });
}

/** Orders */

export function getOrderList(): Promise<ResponseBody<Array<OrderType>>> {
  return request.get({ path: '/orders' });
}

export function getLastOrder(): Promise<ResponseBody<OrderType>> {
  return request.get({ path: '/orders/last' });
}

export type ReviewPayload = {
  review: string;
  mark: number;
};

export function reviewOrder(
  orderId: number,
  payload: ReviewPayload
): Promise<ResponseBody<OrderType>> {
  return request.post({
    path: `/orders/${orderId}/review`,
    body: { ...payload },
  });
}

export function confirmOrder(
  orderId: number
): Promise<ResponseBody<OrderType>> {
  return request.post({ path: `/orders/${orderId}/confirm` });
}

export function cancelOrder(orderId: number): Promise<ResponseBody<OrderType>> {
  return request.post({ path: `/orders/${orderId}/cancel` });
}

export function getBusyDates(): Promise<ResponseBody<Array<BusyDateType>>> {
  return request.get({ path: `/orders/busy-dates` });
}

export function getUsersFromSearch(
  query: string
): Promise<ResponseBody<Array<UserType>>> {
  return request.get({ path: `/order/users`, params: { query } });
}

/** Order Services */

export function getOrderServices(): Promise<
  ResponseBody<Array<OrderServiceType>>
> {
  return request.get({ path: `/services/secondary` });
}

export type Code = {
  success: boolean;
  error: string;
  videoUrl: string;
};

export function sendCode(code: string): Promise<ResponseBody<Code>> {
  return request.post({
    path: '/training/login',
    body: { code },
  });
}

export function submitLeads(payload: FormValuesType) {
  return request.post({
    path: '/leads/request',
    body: payload,
  });
}

export function submitFormSubscription(params: { phone: string }) {
  return request.post({ path: '/leads/subscription', body: params });
}

export function authPhoneStart(phone: string): Promise<
  ResponseBody<{
    session: string;
    code: string | null;
  }>
> {
  return request.post({ path: `/auth/phone-start`, body: { phone } });
}

export function authPhoneCode(
  session: string,
  code: string
): Promise<
  ResponseBody<{
    token: string | null;
  }>
> {
  return request.post({ path: `/auth/phone-code`, body: { session, code } });
}

export function authPhoneSignup(
  session: string,
  code: string,
  name: string,
  email: string
): Promise<
  ResponseBody<{
    token: string;
  }>
> {
  return request.post({
    path: `/auth/phone-signup`,
    body: { session, code, name, email },
  });
}

export function getPrimaryOrderServices(params: {
  names: string;
}): Promise<ResponseBody<Array<PrimaryOrderServiceType>>> {
  return request.get({ path: `/services/primary`, params });
}

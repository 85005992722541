import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  api,
  createResourceLoader,
  Nullable,
  ResourceType,
  shouldGetResourceDataFromCache,
} from '@tager/web-core';

import { AppState, AppThunk } from '@/store/store';
import { getUserProfile, signIn } from '@/services/requests/requests';
import { UserProfileType } from '@/typings/model';

export type AuthStatus = 'AUTHORIZED' | 'UNAUTHORIZED';

const userProfileLoader = createResourceLoader<Nullable<UserProfileType>>(null);

export type PreorderData = Nullable<{
  rooms?: number;
  bathrooms?: number;
  windows?: number;
  balconies?: number;
}>;

type AuthState = {
  userProfile: ResourceType<Nullable<UserProfileType>>;
  status: AuthStatus;
  preorderData: PreorderData;
};

const initialState: AuthState = {
  userProfile: userProfileLoader.getInitialResource(),
  status: 'UNAUTHORIZED',
  preorderData: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    userAuthorized(state, action: PayloadAction<Nullable<UserProfileType>>) {
      state.status = 'AUTHORIZED';

      if (action.payload) {
        state.userProfile = userProfileLoader.fulfill(action.payload);
      }
    },
    userUnauthorized(state) {
      state.status = 'UNAUTHORIZED';
      state.userProfile = userProfileLoader.getInitialResource();
    },
    userProfileRequestPending(state) {
      state.userProfile = userProfileLoader.pending(state.userProfile.data);
    },
    userProfileRequestFulfilled(state, action: PayloadAction<UserProfileType>) {
      state.userProfile = userProfileLoader.fulfill(action.payload);
    },
    userProfileRequestRejected(state) {
      state.userProfile = userProfileLoader.reject();
    },

    setPreorderData(
      state,
      action: PayloadAction<{
        rooms?: number;
        bathrooms?: number;
        windows?: number;
        balconies?: number;
      }>
    ) {
      state.preorderData = action.payload;
    },

    resetPreorderData(state) {
      state.preorderData = null;
    },
  },
});

export function setUserAuthorizedThunk(): AppThunk<
  Promise<Nullable<string> | void>
> {
  return async (dispatch, getState) => {
    dispatch(actions.userAuthorized(null));
  };
}

const actions = authSlice.actions;

export const setPreorderData = actions.setPreorderData;
export const resetPreorderData = actions.resetPreorderData;

export function signInThunk(
  ...args: Parameters<typeof signIn>
): AppThunk<Promise<Nullable<string> | void>> {
  return async (dispatch, getState) => {
    const isUserAuthorized = selectIsUserAuthorized(getState());

    if (isUserAuthorized) return;

    try {
      const oauthTokensResponse = await signIn(...args);
      api.setAccessToken(oauthTokensResponse.data.accessToken);
      api.setRefreshToken(oauthTokensResponse.data.refreshToken);

      await dispatch(getUserProfileThunk());
      dispatch(actions.userAuthorized(null));
    } catch (error) {
      api.setAccessToken(null);
      api.setRefreshToken(null);

      return Promise.reject(error);
    }
  };
}

export function checkAuthorizationThunk(): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const isUserAuthorized = selectIsUserAuthorized(getState());
    if (isUserAuthorized) return;

    try {
      const response = await getUserProfile();
      dispatch(actions.userAuthorized(response.data));
    } catch (error) {
      api.setAccessToken(null);
      api.setRefreshToken(null);
    }
  };
}

export function getUserProfileThunk(options?: {
  shouldInvalidate?: boolean;
}): AppThunk<Promise<Nullable<UserProfileType>>> {
  return async (dispatch, getState) => {
    const userProfileResource = selectUserProfileResource(getState());

    const shouldGetDataFromCache = shouldGetResourceDataFromCache(
      userProfileResource,
      options?.shouldInvalidate
    );

    if (shouldGetDataFromCache) {
      return userProfileResource.data;
    }

    dispatch(actions.userProfileRequestPending());

    try {
      const response = await getUserProfile();
      dispatch(actions.userProfileRequestFulfilled(response.data));

      return response.data;
    } catch (error) {
      dispatch(actions.userProfileRequestRejected());
      return null;
    }
  };
}

export function signOutThunk(): AppThunk<Promise<void>> {
  return async (dispatch, getState) => {
    const isUserAuthorized = selectIsUserAuthorized(getState());

    if (!isUserAuthorized) return;

    try {
      dispatch(actions.userUnauthorized());

      api.setAccessToken(null);
      api.setRefreshToken(null);
    } catch (error) {}
  };
}

export function selectIsUserAuthorized(state: AppState): boolean {
  return state.auth.status === 'AUTHORIZED';
}

export function selectAuthStatus(state: AppState): AuthStatus {
  return state.auth.status;
}

export function selectUserProfileResource(
  state: AppState
): ResourceType<Nullable<UserProfileType>> {
  return state.auth.userProfile;
}

export function selectUserProfile(state: AppState): Nullable<UserProfileType> {
  return selectUserProfileResource(state).data;
}

export function selectPreorderData(state: AppState): PreorderData {
  return state.auth.preorderData;
}

export default authSlice.reducer;

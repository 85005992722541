import { combineReducers } from 'redux';

import { AppState } from '@/store/store';
import { PromoCodeDiscountType } from '@/enums/promo-code-discount-type';
import { PromoCodeType } from '@/enums/promo-code-type';
import { OrderHouseType } from '@/enums/order-house-type';

import common, { selectSubscriptionDiscount } from './common';
import services from './services';
import secondaryServices, {
  selectOrderSecondaryServicesSelected,
  selectOrderSecondaryServices,
} from './secondary-services';
import datetime, { selectSelectedDateCoefficient } from './datetime';
import personal from './personal';
import address from './address';
import subscription from './subscription';
import promo from './promo';
import firstStep from './first-step';

export default combineReducers({
  common,
  services,
  secondaryServices,
  subscription,
  datetime,
  address,
  personal,
  promo,
  firstStep,
});

export function selectPriceForPrimaryServices(state: AppState): number {
  const firstPrimaryServiceOptions =
    state.orderForm.services.firstServiceOptions;
  const firstPrimaryServiceValue = state.orderForm.services.firstServiceValue;

  const secondPrimaryServiceOptions =
    state.orderForm.services.secondServiceOptions;
  const secondPrimaryServiceValue = state.orderForm.services.secondServiceValue;

  const foundFirstServiceOption = firstPrimaryServiceOptions.find(
    (item) => item.id === firstPrimaryServiceValue?.id
  );
  const foundSecondServiceOption = secondPrimaryServiceOptions.find(
    (item) => item.id === secondPrimaryServiceValue?.id
  );

  const result =
    (foundFirstServiceOption?.price || 0) +
    (foundSecondServiceOption?.price || 0);

  return Math.round(result * 100) / 100;
}

export function selectPriceWithoutDiscount(state: AppState): number {
  let price = selectPriceForPrimaryServices(state);

  const secondaryServices = selectOrderSecondaryServices(state);
  const secondaryServicesSelected = selectOrderSecondaryServicesSelected(state);

  secondaryServicesSelected.forEach((secondaryServiceValue) => {
    const service = secondaryServices.find(
      (item) => item.id === secondaryServiceValue.id
    );

    if (!service) return;

    let servicePrice = service.price;
    if (service.mode === 'QUANTITY') {
      servicePrice = service.price * Number(secondaryServiceValue.value);
    } else if (service.mode === 'TIME') {
      servicePrice =
        service.price * (Number(secondaryServiceValue.value) / service.step);
    }

    price += servicePrice;
  });

  price = Math.round(price * 100) / 100;

  return price;
}

export function selectBasePriceForDiscount(state: AppState): number {
  const promoValue = state.orderForm.promo.value;
  const referralCodeDiscount =
    promoValue && promoValue.type === PromoCodeType.Referral
      ? promoValue.discount
      : 0;
  const userBalance = state.orderForm.common.usedBalance || 0;

  return Math.max(
    0,
    selectPriceWithoutDiscount(state) - userBalance - referralCodeDiscount
  );
}

export function selectOrderLength(state: AppState): number {
  const firstPrimaryServiceOptions =
    state.orderForm.services.firstServiceOptions;
  const firstPrimaryServiceValue = state.orderForm.services.firstServiceValue;

  const secondPrimaryServiceOptions =
    state.orderForm.services.secondServiceOptions;
  const secondPrimaryServiceValue = state.orderForm.services.secondServiceValue;

  const foundFirstServiceOption = firstPrimaryServiceOptions.find(
    (item) => item.id === firstPrimaryServiceValue?.id
  );
  const foundSecondServiceOption = secondPrimaryServiceOptions.find(
    (item) => item.id === secondPrimaryServiceValue?.id
  );

  return (
    (foundFirstServiceOption?.minutes || 0) +
    (foundSecondServiceOption?.minutes || 0)
  );
}

export function selectUserPrice(state: AppState): number {
  let price = selectBasePriceForDiscount(state);

  if (state.orderForm.address.type === OrderHouseType.House) {
    const primaryServicesPrice = selectPriceForPrimaryServices(state);
    price += primaryServicesPrice * 1.2 - primaryServicesPrice;
  }

  const dateCoefficient = selectSelectedDateCoefficient(state);
  const promoValue = state.orderForm.promo.value;

  if (dateCoefficient > 1) {
    price = price * dateCoefficient;
  }

  const promoDiscount =
    promoValue && promoValue.type === PromoCodeType.Promo
      ? promoValue.discountType === PromoCodeDiscountType.Amount
        ? promoValue.discount
        : price * promoValue.discount * 0.01
      : 0;

  const dateDiscount =
    dateCoefficient && dateCoefficient < 1 ? price * (1 - dateCoefficient) : 0;

  const subscriptionDiscount =
    price * (selectSubscriptionDiscount(state) / 100);

  const maxDiscount = Math.max(
    promoDiscount,
    dateDiscount,
    subscriptionDiscount
  );

  price = price - maxDiscount;

  return Math.round(price * 100) / 100;
}

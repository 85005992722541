import React from 'react';
import styled from 'styled-components';

import { visuallyHidden } from '@tager/web-components';

import { colors } from '@/constants/theme';

import { ReactComponent as HouseIcon } from './svg/house.svg';
import { ReactComponent as CheckIcon } from './svg/check.svg';

type Props = {
  checked: boolean;
  onChange: (value: boolean) => void;
};

const OrderFormHouseCheckbox: React.FC<Props> = ({
  checked,
  onChange,
  ...rest
}) => {
  return (
    <Component {...rest} htmlFor="id">
      <Input
        type="checkbox"
        id={'id'}
        checked={checked}
        onChange={(e) => onChange(e.target.checked)}
      />
      <Inner>
        <CheckboxIcon>
          <CheckIcon />
        </CheckboxIcon>
        <HouseIcon />
        <Text>Частный дом</Text>
        <Coefficient>x 1.2</Coefficient>
      </Inner>
    </Component>
  );
};

const Input = styled.input`
  ${visuallyHidden}
`;

const CheckboxIcon = styled.div`
  position: absolute;
  left: 5px;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  transition: 0.3s all ease;
`;

const Inner = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 1rem;
  padding-left: 40px;
  position: relative;
  cursor: pointer;

  > svg {
    height: 32px;
    width: 32px;
  }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    width: 25px;
    height: 25px;
    border: 1px solid rgba(18, 207, 124, 0.8);
    border-radius: 5px;
    transition: 0.3s all ease;
  }
`;

const Component = styled.label`
  display: flex;
  align-items: center;

  > input:checked + ${Inner} {
    ${CheckboxIcon} {
      opacity: 1;
    }
    &:before {
      background: rgba(18, 207, 124, 0.8);
    }
  }
`;

const Text = styled.span`
  display: block;
  font-weight: bold;
`;

const Coefficient = styled.span`
  display: block;
  background: ${colors.green};
  color: ${colors.white};
  padding: 4px 7px;
  border-radius: 5px;
  font-weight: bold;
`;

export default OrderFormHouseCheckbox;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppState } from '@/store/store';
import { loadCities } from '@/store/reducers/cities';
import { City } from '@/services/requests/misc';
import { OrderHouseType } from '@/enums/order-house-type';

type AddressState = {
  type: OrderHouseType;
  cityId: number | null;
  cityName: string | null;
  street: string;
  house: string;
  frame: string;
  flat: string;
  entrance: string;
  floor: number | undefined;
  intercomCode: string;
};

const initialState: AddressState = {
  type: OrderHouseType.Apartment,
  cityId: null,
  cityName: null,
  street: '',
  house: '',
  frame: '',
  flat: '',
  entrance: '',
  floor: undefined,
  intercomCode: '',
};

const addressSlice = createSlice({
  name: 'address',
  initialState,
  reducers: {
    reset: () => {},
    setCity(state, action: PayloadAction<City | null>) {
      state.cityId = action.payload ? action.payload.id : null;
      state.cityName = action.payload ? action.payload.name : null;
    },
    setStreet(state, action: PayloadAction<string>) {
      state.street = action.payload;
    },
    setHouse(state, action: PayloadAction<string>) {
      state.house = action.payload;
    },
    setFrame(state, action: PayloadAction<string>) {
      state.frame = action.payload;
    },
    setFlat(state, action: PayloadAction<string>) {
      state.flat = action.payload;
    },
    setEntrance(state, action: PayloadAction<string>) {
      state.entrance = action.payload;
    },
    setFloor(state, action: PayloadAction<number | null>) {
      state.floor = action.payload || undefined;
    },
    setIntercomCode(state, action: PayloadAction<string>) {
      state.intercomCode = action.payload;
    },
    setType(state, action: PayloadAction<OrderHouseType>) {
      state.type = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(loadCities.fulfilled, (state, action) => {
      state.cityId = action.payload[0].id;
      state.cityName = action.payload[0].name;
    });
  },
});
export const addressActions = addressSlice.actions;

export const selectAddressCityId = (state: AppState) =>
  state.orderForm.address.cityId;
export const selectAddressCityName = (state: AppState) =>
  state.orderForm.address.cityName;
export const selectAddressStreet = (state: AppState) =>
  state.orderForm.address.street;
export const selectAddressHouse = (state: AppState) =>
  state.orderForm.address.house;
export const selectAddressFrame = (state: AppState) =>
  state.orderForm.address.frame;
export const selectAddressFlat = (state: AppState) =>
  state.orderForm.address.flat;
export const selectAddressEntrance = (state: AppState) =>
  state.orderForm.address.entrance;
export const selectAddressFloor = (state: AppState) =>
  state.orderForm.address.floor;
export const selectAddressIntercomCode = (state: AppState) =>
  state.orderForm.address.intercomCode;
export const selectAddressType = (state: AppState) =>
  state.orderForm.address.type;

export default addressSlice.reducer;
